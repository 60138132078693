

//import axios from 'axios';
import apiClient from '@/plugins/http-common';

import { Credentials } from '@/models/credentials.interface';
import { BaseService } from './base.service';
import { Observable, of, from } from 'rxjs'; 
import { map, catchError } from 'rxjs/operators';
import { UserRole } from '../helpers/userRoles';



class AuthService extends BaseService {

    private static instance: AuthService;

    private constructor() { super(); }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this.instance || (this.instance = new this());
    }


    // testing:
    // This should come from Vuex instead - not a web service call
    public getCurrentUser() {
        return {            
                id: 101,
                role: UserRole.COLLEGEADMIN,
                name: 'first last name',
                email: 'myemail@school.edu',
        };
        
    }

    public login(credentials: Credentials): Observable<any> {

        return from(apiClient.post('/api/auth/login', credentials))
            .pipe(
                map((res: any) => res.data),
                catchError((error: any) => this.handleError(error.response)),
                );
        // map((res: any) => res.data.auth_token),
    }



    public refreshLoginState_simp() {
        return apiClient.get('/api/profile/me');
    }


    public refreshLoginState(): Observable<any> {

        // api/profile/me  
        //return from(axios.post('/api/auth/loginLti'))
        return from(apiClient.get('/api/profile/me'))
            .pipe(
                map((res: any) => res.data),
                catchError((error: any) => this.handleError(error.response)),
            );
        // map((res: any) => res.data.auth_token),
    }


    public logout(): Observable<any> {

        return from(apiClient.post('/api/auth/logout'))
            .pipe(
                map((res: any) => res.data),
                catchError((error: any) => this.handleError(error.response)),
            );                
    }



    public checkLoginStatus(): Observable<any> {

        return from(apiClient.get('/api/auth/login-status'))
            .pipe(
                map((res: any) => res.data),
                catchError((error: any) => this.handleError(error.response)),
            );        
    }



//    public facebookLogin(accessToken: string): Observable<any> {
        
        //return Observable.fromPromise(axios.post(`${this.api}/externalauth/facebook`, { accessToken }))
        //    .map((res: any) => res.data.auth_token)
        //    .catch((error: any) => this.handleError(error.response));
  //  }
}

// export a singleton instance in the global namespace
export const authService = AuthService.Instance;


