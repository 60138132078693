    
    import { Component, Watch, Ref, Vue } from 'vue-property-decorator';
    
    import { EventBus } from './event-bus';
    import { mapGetters } from 'vuex';

    import { UserAccount } from '@/models/UserAccount';
    import { UserRole } from '@/helpers/userRoles';

    import { accountService } from '@/services/account.service';




    @Component({
        //components: { HelloWorld },
        computed: mapGetters({
            isAuthenticated: 'auth/isAuthenticated',  // needed for runtime
            //userProfile: 'user/profile',
            authUser: 'auth/authUser',
            authRole: 'auth/authRole',
            userOrgName: 'auth/userOrgName',
            userOrgNameShort: 'auth/userOrgNameShort',
            demo: 'mode',
            count: 'counter',
        }),
        //computed() {
        //    otherName() {
        //        return this.$store.getters.profile;
        //    }
        //},
        metaInfo: {
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'description', content: 'EdTech Commons is a repository of product information about tools purchased for and used by colleges in the Washington CTC system.' },
                { property: 'og:title', content: 'EdTech Commons' },
                { property: 'og:site_name', content: 'EdTech Commons' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' },
            ],
        },
    })

    export default class App extends Vue {

        private menu: boolean = false;
        private profileMenu: boolean = false;
        private clipped: boolean = true;
        private drawer: boolean = false;
        private miniVariant: boolean = false;
        private right: boolean = false;
        private snackbar: boolean = true;

        //private timer: number = 0;

        private messageCount: number = 0;

        private appTitlePrefix: string = 'EdTech Commons';
        private institutionName: string = 'My College';
        private instLogoSmallUrl: string = ''; //'/img/institutions/no-name-sm-round.png';

        private transitionName: string = 'slide-left';
        private userRole = UserRole;

        // Getters declared (again) for static type checking
        private isAuthenticated!: boolean; //any[];
        private authUser!: UserAccount;
        private authRole!: string;
        private userOrgName!: string;        
        // private userProfile!: any[];



        private menuItems = [

            { title: 'Home', link: '/' },  //icon: 'home',
            { title: 'Products', link: '/product' },  // icon: 'dashboard',
            //{ title: 'Vendors', icon: 'mdi-office-building', link: '/vendor' },
            { title: 'Evaluation', link: '/evaluations' },            // icon: 'test-tube',
            { title: 'News', link: '/news' },  // icon: 'mdi-newspaper-variant-outline',

            { title: 'Privacy', icon: 'account-question-outline', link: '//www.sbctc.edu/privacy.aspx' },

            { title: 'About', link: '/about' }, // icon: 'mdi-information-outline',
            //{ title: 'Support', icon: 'mdi-help-circle-outline', link: '/support' },

        ];

        
        // Vue/Veutify caching of pages and components:
        private cachedViewsList = [
            'ProductGrid', 'About', 'News', 'Evaluations', 'Contracts',
            'ContentItemBlock'];  // ProductDetailView,

        private cachedViews = this.cachedViewsList;



        private alertItems = [

            { text: 'Private Messages', icon: 'mdi-account-multiple' },
            { text: 'Favorites', icon: 'mdi-star' },
            { text: 'To Review', icon: 'mdi-history' },
            { text: 'Files', icon: 'mdi-folder' },
            { text: 'Finished', icon: 'mdi-check-circle' },
            { text: 'Uploads', icon: 'mdi-upload' },

        ];

        get metaInfo() {
            return {
                title: `${this.$route.name} - EdTech Commons`,
                meta: [
                    { name: 'description', content: this.appTitlePrefix + 'Connect and follow ' },
                    { property: 'og:title', content: this.appTitlePrefix },
                    { property: 'og:site_name', content: this.appTitlePrefix },
                    { property: 'og:description', content: 'Connect and follow ' },
                    { property: 'og:type', content: 'profile' },
                    { property: 'og:url', content: 'https://commons.edtech.sbctc.edu' },
                    { property: 'og:image', content: 'https://commons.edtech.sbctc.edu/images/main.jpg' },
                ],
            };
        }

        get userInitials() {
            try {
                return this.authUser.firstName.substr(0, 1) + this.authUser.lastName.substr(0, 1);
            } catch {
                return '?';
            }
        }


        @Watch('profileMenu', { immediate: false, deep: true })
        public handleProfileMenuTransition() {
            if (this.profileMenu) {

                setTimeout(() => {
                    //this.$refs.firstProfileMenuLink.$el.focus();
                    ((this.$refs.firstProfileMenuLink as Vue).$el as HTMLElement).focus();
                }, 100);  // ms delay
                //this.$nextTick(() => this.$refs.firstProfileMenuLink.$el.focus());              

            } else {
                // set focus back to menu button                
                //this.$nextTick(() => {
                requestAnimationFrame(() => {

                    ((this.$refs.profileMenuButton as Vue).$el as HTMLElement).focus();

                });
            }
        }


        @Watch('isAuthenticated', { immediate: true, deep: true })
        public HandleAuthStatusChange() {
            console.log('HandleAuthStatusChange - App.vue');
            // clear cached view from KeepAlive

            // this array reset isn't working:
            //let origList = this.cachedViews;
            requestAnimationFrame(() => {
                this.cachedViews.length = 0; // empty the cached view array - force reloads   //= [];
            });

            window.setTimeout(() =>
                this.cachedViews = this.cachedViewsList  // set full cached view list
            , 300);

           
        }


        private getRoleName(role: string) {
            return accountService.getRoleDisplayName(role);
        }


        get pageUrl() {
            // hardcoding the current domain:
            return 'https://commons.edtech.sbctc.edu' + this.$route.fullPath;
        }

        private logoff() {
            this.$store.dispatch('auth/authLogout').then(() => {
                this.$router.push('/product');
            });
        }


        private checkLoginStatus() {
            // makes call to service and web server - effectively validating jwt in cookie

            // lightweight check of login status  - this.authUser != null
            if (this.isAuthenticated
                && Object.keys(this.authUser).length > 0) {
                // makes call to service and web server - effectively validating jwt in cookie
                this.$store.dispatch('auth/authStatusCheck');                
            } else {
                // Heavier weight get profile info, to set login info to local storage.
                // Handles an LTI login, for example. LTI landing page: ExternalLogin.vue
                this.$store.dispatch('auth/authRefreshLoginState');

            }
            if (this.isAuthenticated) {
                // recurse
                window.setTimeout(() => this.checkLoginStatus(), 300000); // 300,000 = 5 min
            }
        }



        private created() {

            // set focus to Products menu item:
            //this.$nextTick(() => {
            requestAnimationFrame(() => {
                ((this.$refs.homeHeaderLink as Vue).$el as HTMLElement).focus();                
            });

            // test           
            //console.log(localStorage.getItem('current-user'));

            this.checkLoginStatus();
            //if (this.isAuthenticated) {
            //}

            EventBus.$on('logged-in', (payLoad: any) => {
                // This doesn't currently do anything in this demo but does get fired on successful login.
                // leaving it  to show how to allow communication between unrelated components - ie. Store -> Component
                //console.log('logged-in message received...');
            });
        }

        private beforeDestroyed() {


        }



        private destroyed() {
            EventBus.$off('logged-in');

            //let elHtml = document.getElementsByTagName('html')[0];
            //elHtml.style.overflowY = ''; //null;
        }


        private mounted() {



            //let elHtml = document.getElementsByTagName('html')[0];
            //elHtml.style.overflowY = 'auto';
        }


    }
