/*   Client alerts - Vuex store
 * 
 * 
 */
import { Alert } from '@/models/Alert';




const state = {
    type: null,
    message: null,

    personalAlerts: getUserAlerts(),

};


const getters = {
    alertMessage: (alertState: any) => alertState.message, 
    alertType: (alertState: any) => alertState.type,

    alerts: (alertState: any) => alertState.personalAlerts,  


};


const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
};


const mutations = {
    success(alertState, message) {
        alertState.type = 'alert-success';
        alertState.message = message;
    },
    error(alertState, message) {
        alertState.type = 'alert-danger';
        alertState.message = message;
    },
    clear(alertState) {
        alertState.type = null;
        alertState.message = null;
    },
};


// VUEX exports:
export default {   // const alert =
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};




function getUserAlerts() {

    let alerts: Alert[] = [];

    alerts.push({
        id: 1,
        name: 'Test Alert',
        body: 'main message body',
        excerpt: 'summary',
        status: 'active',
        severity: 'info',
        actionUrl: 'https://commons.edtech.sbctc.edu/contracts',
        visibilityRole: 'member',

        organizationName: 'ETAG',
        createdBy: 'ETAG',
        //createdOn = '4-12-2022',

        productId: 101,
        productSlug: 'ally',
        productName: 'Ally',

        } as Alert
    );
    return alerts;
}
