// testing different axios uses/layers 

import axios, { AxiosInstance } from 'axios';


const apiClient: AxiosInstance = axios.create({
    //baseURL: "https://localhost:8080",
    timeout: 50 * 1000, // Timeout 50 sec

    headers: {
        'Content-type': 'application/json',
    },
});

export default apiClient;
