/*  General app Vuex store
 *
 *
 */

import { ActionTree } from 'vuex';
import { GetterTree } from 'vuex';
import { MutationTree } from 'vuex';
import { Module } from 'vuex';


import { RootState } from '../types';

// import { EventBus } from '../../event-bus';
//import DemoState from '../../models/DemoState';




const state = {
    isLoading: false,
    isLoadingError: false,
    loadingMsg: 'starting...',

};

//export const state: DemoState = {
//    Mode: true,
//    State: '',
//};



const getters = {
    isLoading: (mainState: any) => mainState.isLoading,
    isLoadingError: (mainState: any) => mainState.isLoadingError,
    loadingMsg: (mainState: any) => mainState.loadingMsg,

    //alertType: (alertState: any) => alertState.type,
    //alerts: (alertState: any) => alertState.personalAlerts,

};
//export const getters: GetterTree<DemoState, RootState> = {
//    mode(demoState): boolean {
//        return demoState.Mode;
//    },
//};


export const mutations = { //: MutationTree<DemoState> = {
    loading(mainState) {
        mainState.isLoading = true;
    },
    notLoading(mainState) {
        mainState.isLoading = false;
    },

    loadingError(mainState) {
        mainState.isLoadingError = true;
    },
    notLoadingError(mainState) {
        mainState.isLoadingError = false;

    },

    setLoadingMsg(mainState, message) {
        mainState.loadingMsg = message;
    },

    //incrementCounter(state) {
    //    state.counter++;
    //},
    //resetCounter(state) {
    //    state.counter = 0;
    //},
};


export const actions = { //: ActionTree<DemoState, RootState> = {
    loading({ commit }): any {
        commit('isLoading', true);
    },
    notLoading({ commit }): any {
        commit('isLoading', false);
    },
    loadingError({ commit }): any {
        commit('isLoadingError', true);
    },
    notLoadingError({ commit }): any {
        commit('isLoadingError', false);
    },

    setLoadingMsg({ commit }, message): any {
        commit('loadingMsg', message);
    },

    //increment({ commit }): any {
    //    commit('incrementCounter');
    //},
    //reset({ commit }): any {
    //    commit('resetCounter');
    //},
};



export default { //const demoMode: Module<DemoState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

