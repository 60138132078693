import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import VueMeta from 'vue-meta';


import main from './modules/main';
import auth from './modules/auth';
import alert from './modules/alert';

//import { demoMode } from './modules/main';

//import user from './modules/user';
//import { counter } from './counter/index';



Vue.use(Vuex, VueMeta, { refreshOnceOnNavigation: true });


// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0', // a simple property
    },
    mutations: {

    },
    actions: {

    },
    modules: {
        //alert,
        alert: {
            namespaced: true,
            state: alert.state,
            mutations: alert.mutations,
            getters: alert.getters,
            actions: alert.actions,
        },

        main,
        //counter,
       //demoMode,

        auth: {
            namespaced: true,
            state: auth.state,
            mutations: auth.mutations,
            getters: auth.getters,
            actions: auth.actions,
        },
        //user: {
        //    namespaced: true,
        //    state: user.state,
        //    actions: user.actions,
        //    mutations: user.mutations,
        //    getters: user.getters,
        //},

        //contractTypes, // 
        //visibilityLevels, //
        // userRoles, //


  },
};

export default new Vuex.Store<RootState>(store);


