import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
//import './plugins/axios';  // testing if okay to remove
import axios from 'axios';
import vuetify from './plugins/vuetify';


import router from './router';
import store from '@/store/index';
import './registerServiceWorker';

import App from './App.vue';

//import { UserRole } from './helpers/userRoles';  


import dateFilter from '@/filters/date.filter';
//import slugFilter from '@/filters/slug.filter';
// v-mask, as a directive
import { VueMaskDirective } from 'v-mask';
import { request } from 'https';

import './plugins/tipTapVuetify';


// Disable the 'development mode' warning messages
Vue.config.productionTip = false;


// FILTERS - Vue 2, removed in Vue 3
//Vue.filter('date', dateFilter);

// App utility functions - Vue3-ready
Vue.prototype.$dateFormat = dateFilter; // use as: $dateFormat([date])


// Tested - these seemed unreliable:
//Vue.prototype.$userRole = store.getters['auth/authRole']; 
//Vue.prototype.$userOrgId = store.getters['auth/userOrgId'];


// DIRECTIVES
Vue.directive('mask', VueMaskDirective);



new Vue({
  vuetify,
  router,
  store,
    render: (h) => h(App),  
}).$mount('#app');





// AXIOS - interceptors
// Collecting these here instead of in axios.js, as,
// the handling often uses additional app features like router. 
// Better dependency chain with less coupling?

// Or, move into /plugins/http-common.js? my axios wrapper

// AUTH TOKEN - pass w/ each request if valid token
// unused, original handling of JWT, reading from localstorage and passing via header
//axios.interceptors.request.use((config: any) => {
//    const authToken = store.getters['auth/authToken'];
//    if (authToken) {
//        config.headers.Authorization = `Bearer ${authToken}`;
//    }
//    return config;
//}, (err: any) => {
//    return Promise.reject(err);
//});


// Is this active? I think no... 3-25-2019
// RESPONSE
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.log(error.response.data);
    if (error.response.status === 401) {
        //store.dispatch('logout')
        //router.push('/users/login')
        //this.$store.dispatch('auth/authLogout').then(() => {
        //    this.$router.push('/product');
        //});

        //console.log('axios interceptor error URL: ' + error.response.url);

        //// Handling for special URL(s)
        //if (!error.response.url.includes('login-status')) {

        //    // route to login form
        //    store.dispatch('auth/authLogout');
        //    router.push('/users/login');
        //}
    }
    return Promise.reject(error);
});



