

// Used for authorization, in router.ts and auth service code
//
// So, this is fine, but I think we need the display values along with the
// the domain/db values.
// Each should be an object w/ id and name

// TODO: get these from server-side app
// need actual .ts class in models folder

export const UserRole = {

    NONE: '',               // no access
    BASE: 'Base',           // Staff? Lowest access - granted w/ LTI login
    ANYROLE: 'AnyRole',     // open to all roles
    ADMINSUFFIX: 'ADMIN',   // any admin

    APPSUPER: 'AppSuper',   // top role

    ORGADMIN: 'OrgAdmin',
    ORGSTAFF: 'OrgStaff',
    ORGSUPER: 'OrgSuper',

    
    COLLEGESTAFF: 'CollegeStaff',    
    COLLEGEADMIN: 'CollegeAdmin',

    // Retiring these, combining into Orgxxx ??
    COLLEGESUPER: 'CollegeSuper',

    GROUPADMIN: 'GroupAdmin',
    GROUPSTAFF: 'GroupStaff',
    
};



