import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';

import colors from 'vuetify/lib/util/colors';

import Vuetify, {
    VAlert,
    VApp,
    VLayout,
    VNavigationDrawer,
    VBreadcrumbs,
    VFooter,
    VFlex,
    VList,
    VCard,
    VBtn,
    VIcon,
    VImg,
    VToolbar,
    VBanner,
    VDataTable,
    VProgressLinear,
    VTooltip,
    VForm,

} from 'vuetify/lib';

// vue-cli a-la-carte installation
Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VLayout,
    VNavigationDrawer,
    VFlex,
    VFooter,
    VBreadcrumbs,
    VList,
    VCard,    
    VBtn,
    VIcon,
    VImg,
    VToolbar,
    VBanner,
    VDataTable,
    VProgressLinear,
    VTooltip,
    VForm,                    
  },
});

const opts = {
  theme: {
    themes: {


      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
            success: '#4CAF50',
            warning: '#ffecb3',
      },
          dark: {
              primary: colors.blue.lighten3,
          },
    },
    },

    /* tan: #9f9c95
     * 
     */
};

export default new Vuetify(opts);
