/* tslint:disable:no-console */

import { register } from 'register-service-worker';


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'EdTech Commons is served from cache by a service worker.\n',
        
      );
    },
    cached() {
      console.log('Content has been cached for offline use.');
      },
      updated() {
          console.log('New content is available; Refresh...');
          setTimeout(() => {
              window.location.reload();  // was: window.location.reload(true);
          }, 1000);
      },
    //updated() {
    //  console.log('New content is available; please refresh.');
    //},
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
