// NOTES: this may be the only service that inherits from BaseService
//  TODO: Is it worth anything?


import apiClient from '@/plugins/http-common';

import { UserAccount } from '@/models/UserAccount';
import { UserRegistration } from '@/models/user.registration.interface';
import { UserPasswordUpdate } from '@/models/UserPasswordUpdate';
import { UserRole } from '@/models/UserRole';
import { NewUser } from '@/models/NewUser';

import { UserRole as Role } from '@/helpers/userRoles';  

import store from '@/store/index';

import { BaseService } from './base.service';
import { Observable, of, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';




class AccountService extends BaseService {

    private static instance: AccountService;

    private constructor() { super(); }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this.instance || (this.instance = new this());
    }


    public async getAll(): Promise<any> {
        return apiClient.get<UserAccount[]>('/api/accounts/all');
    }



    // REGISTER
    //public register(userRegistration: UserRegistration): Observable<any> {
    public register(userRegistration: UserRegistration) {

        return apiClient.post('/api/accounts', userRegistration);

        // updated orig:
        //return from(apiClient.post('api/accounts', userRegistration))
        //    .pipe(
        //        map((res: any) => true),
        //        catchError((error: any) => this.handleError(error.response)),
        //    );

        //return Observable.fromPromise(apiClient.post(`${this.api}/accounts`, userRegistration))
        //    .map((res: any) => true)
        //    .catch((error: any) => this.handleError(error.response));

    }




    public setPassword(userName: string, data: UserPasswordUpdate) {
        return apiClient.patch('/api/accounts/' + userName + '/updatepassword', data); 
    }



    public setMyPassword(data: UserPasswordUpdate) {

        return apiClient.patch('/api/accounts/me/updatepassword', data);

    }

    // mockup - admin role check
    public isAdmin(): Observable<any> {

        return from(apiClient.post('/api/accounts/me/role'))
            .pipe(
                map((res: any) => true),
                catchError((error: any) => this.handleError(error.response)),
            );
    }


    public enableAccount(userId: string) {
        return apiClient.patch(`/api/accounts/${userId}/enable`); // identityId
    }


    public disableAccount(userId: string) {
        return apiClient.patch(`/api/accounts/${userId}/disable`);
    }


    public addAccount(newUser: NewUser) {        
        return apiClient.post(`/api/accounts`, newUser);
    }


    public getUserRoles() {
        // Returns conditional list of roles, depending on user role        
        const currentRole = store.getters['auth/authRole'];
        //let roles = [] as UserRole[];
        let roles = [
            //{
            //    name: 'Public',  // no access
            //    value: '',
            //    id: 0,
            //    status: 'active',
            //} as UserRole,
            {
                name: 'Member',  // minimal org member access
                value: 'Base',
                id: 20,
                status: 'active',
            } as UserRole,
            {
                name: 'Designated Staff',  // college staff - designated w/ additional rights
                value: 'CollegeStaff',
                id: 50,
                status: 'active',
            } as UserRole,           
        ];

        // Admin - college or org admin
        if (currentRole.toUpperCase().includes(Role.ADMINSUFFIX)
            || currentRole.includes(Role.APPSUPER)) {
            roles.push(
                {
                    name: 'Administrator',  // college admin
                    value: 'CollegeAdmin',
                    id: 70,
                    status: 'active',
                } as UserRole,
            );
        }

        //debugger;
        // Super admin - Commons Admin
        if (currentRole.includes(Role.APPSUPER)) {
            roles.push(
                {
                    name: 'Commons Admin',
                    value: 'AppSuper',
                    id: 100,
                    status: 'active',
                } as UserRole,
            );
        }
                   
        return roles;                
    }




    public getRoleDisplayName(role: string) {

        switch (role) {
            case 'Base':
                return 'Member';                
            case 'CollegeAdmin':
                return 'Administrator';
            case 'CollegeStaff':
                return 'Designated Staff';
            case 'AppSuper':
                return 'Commons Admin';

            default:
                return role;
        }        
    }

    public getPwdValidationRules() {
        // return pwdRules: any[] = [
        return [] = [
            v => (v || '').length <= 20 && (v || '').length > 6 || 'Must be from 7 to 20 characters',
            v => /\d/.test(v) || 'Must include one number',
            v => /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(v) || 'Must include a punctuation character',
        ];
        //return pwdRules;
    }

}


// export a singleton instance in the global namespace
export const accountService = AccountService.Instance;

//export default new AccountService();
