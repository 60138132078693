
import Vue from 'vue';
import Router from 'vue-router';
import { PositionResult } from 'vue-router/types/router';
import goTo from 'vuetify/lib/services/goto';


import { UserRole } from './helpers/userRoles';
import store from './store/index';
//import { authService } from './services/auth.service';


Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            meta: { title: 'Home' },
            //component: Home,
            component: () => import(/* webpackChunkName: "public" */ './views/Home.vue'),
        },
        {
            path: '/product',
            name: 'product',
            meta: { title: 'Products' },
            component: () => import(/* webpackChunkName: "main" */ './views/Product.vue'),
        },
        {
            path: '/product/:slug',
            name: 'productDetail',
            meta: { title: 'Product: ', authorize: [UserRole.ANYROLE] },            
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/ProductDetail.vue'),
        },
        {
            path: '/product/:slug/reviews',
            name: 'productReviews',
            meta: { title: 'Reviews: ', authorize: [UserRole.ANYROLE] },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/ProductReviews.vue'),
        },
        {
            path: '/product/:slug/doc/:id',
            name: 'productDoc',
            meta: { title: 'Document: ', authorize: [UserRole.ANYROLE] },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/ProductDoc.vue'),
        },

        {
            path: '/doc/:id',
            name: 'docDisplay',
            meta: { title: 'Document: ' },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './components/DocumentDisplay.vue'),
        },

        {
            path: '/resource/:slug',
            name: 'resourcePage',
            meta: { title: 'Resource Page: ', authorize: [UserRole.ANYROLE] },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/ResourcePage.vue'),
        },

        {
            path: '/vendor',
            name: 'vendor',
            meta: { title: 'Vendors' },
            component: () => import(/* webpackChunkName: "main" */ './views/Vendor.vue'),
        },

        {
            path: '/vendor/:slug',
            name: 'vendorDetail',
            meta: { title: 'Vendor: ' },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/VendorDetail.vue'),
        },

        {
            path: '/support',
            name: 'support',
            meta: { title: 'Support' },
            component: () => import(/* webpackChunkName: "main" */ './views/Support.vue'),
        },

        {
            path: '/demo',
            meta: { title: 'Demo Settings' },
            name: 'demo',
            component: () => import(/* webpackChunkName: "main" */ './views/Demo.vue'),
        },

        {
            path: '/reviews/rubric',
            name: 'rubric',
            meta: { title: 'Rubrics' },
            component: () => import(/* webpackChunkName: "main" */ './components/Rubric.vue'),
        },
        {
            path: '/news',
            name: 'news',
            meta: { title: 'News' },            
            component: () => import(/* webpackChunkName: "public" */ './views/News.vue'),
        },
        {
            path: '/about',
            name: 'about',         
            meta: { title: 'About' },
            component: () => import(/* webpackChunkName: "public" */ './views/About.vue'),
        },
        {
            path: '/contracts',
            name: 'contracts',
            meta: { title: 'Contracts', authorize: [UserRole.ANYROLE] },
            component: () => import(/* webpackChunkName: "public" */ './views/Contracts.vue'),
        },
        {
            path: '/evaluations',
            name: 'evaluations',
            meta: { title: 'Evaluations' },            
            component: () => import(/* webpackChunkName: "public" */ './views/Evaluations.vue'),
        },
        {
            path: '/procurement',
            name: 'procurement',
            meta: { title: 'Procurement Process' },
            component: () => import(/* webpackChunkName: "public" */ './views/Procurement.vue'),
        },
        {
            path: '/privacy',
            name: 'privacy',
            meta: { title: 'Data Privacy' },
            component: () => import(/* webpackChunkName: "public" */ './views/Privacy.vue'),
        },
        {
            path: '/accessibility',
            name: 'accessibility',
            meta: { title: 'Accessibility Information' },
            component: () => import(/* webpackChunkName: "public" */ './views/AccessibilitySiteInfo.vue'),
        },
        {
            path: '/terms-of-use',
            name: 'terms',
            meta: { title: 'Terms of Use' },
            component: () => import(/* webpackChunkName: "public" */ './views/TermsOfUse.vue'),           
        },
        {
            path: '/updates',
            name: 'updates',
            meta: { title: 'Updates', authorize: [UserRole.ANYROLE] },
            component: () => import(/* webpackChunkName: "public" */ './views/AppUpdates.vue'),
        },

        //{
        //    path: '/counter',
        //    name: 'counter',
        //    // route level code-splitting
        //    // this generates a separate chunk (about.[hash].js) for this route
        //    // which is lazy-loaded when the route is visited.
        //    component: () => import(/* webpackChunkName: "test" */ './components/Counter.vue'),
        //},
        //{
        //    path: '/fetch-data',
        //    name: 'fetch-data',
        //    component: () => import(/* webpackChunkName: "test" */ './views/FetchData.vue'),
        //},


        // College Admin
        {
            path: '/admin',
            name: 'admin',
            component: () => import(/* webpackChunkName: "admin" */ './views/Admin.vue'),
            meta: { authorize: [UserRole.APPSUPER, UserRole.COLLEGESUPER, UserRole.COLLEGEADMIN, UserRole.GROUPADMIN], title: 'Admin Settings' },

            //children: [
            //    {
            //        path: '/admin/users',
            //        name: 'admin-users',
            //        meta: { requiresAuth: true, title: 'Users' },
            //        component: () => import(/* webpackChunkName: "admin" */ './views/AdminUsers.vue'),
            //    },
                //{
                //    path: '*',
                //    component: () => import(/* webpackChunkName: "main" */ './views/PageNotFound.vue'),
                //},
            //],
        },

        {
            path: '/admin/users',
            name: 'admin-users',
            meta: { title: 'Users', authorize: [UserRole.APPSUPER, UserRole.COLLEGESUPER, UserRole.COLLEGEADMIN, UserRole.GROUPADMIN] },
            component: () => import(/* webpackChunkName: "admin" */ './components/AdminUsers.vue'),
        },


        {
            path: '/admin/orgs',
            name: 'admin-orgs',
            meta: {
                //requiresAuth: true,
                authorize: [UserRole.APPSUPER, UserRole.COLLEGESUPER, UserRole.COLLEGEADMIN],
                //roles: () => store.getters.auth.authRoles, title: 'Organizations'
            },  // testing: dynamically get roles from vuex
            //meta: { requiresAuth: false, requiresRole: 'admin', title: 'Organizations' },
            component: () => import(/* webpackChunkName: "admin" */ './components/Orgs.vue'),
        },

        {
            path: '/admin/support',
            name: 'admin-support',
            meta: { authorize: [UserRole.APPSUPER, UserRole.COLLEGESUPER, UserRole.COLLEGEADMIN, UserRole.GROUPADMIN], title: 'Help - Admin' },
            component: () => import(/* webpackChunkName: "admin" */ './views/SupportAdmin.vue'),
        },


        // System Settings - top-level
        {
            path: '/admin/sys',
            name: 'admin-sys',
            meta: { authorize: [UserRole.APPSUPER], title: 'System Settings' },
            component: () => import(/* webpackChunkName: "admin" */ './views/System.vue'),
        },

        {
            path: '/users/register',
            name: 'register',
            meta: { authorize: [UserRole.APPSUPER], title: 'Register for account' },
            component: () => import(/* webpackChunkName: "main" */ './components/RegistrationForm.vue'),
        },
        {
            path: '/users/ltilogin',
            name: 'externalLogin',
            meta: { title: 'External Login' },
            component: () => import(/* webpackChunkName: "main" */ './views/ExternalLogin.vue'),
        },
        {
            path: '/users/login',
            name: 'login',
            meta: { title: 'Login' },
            component: () => import(/* webpackChunkName: "main" */ './components/Login.vue'),
        },
        {
            path: '/users/profile',
            name: 'profile',
            meta: { authorize: [UserRole.ANYROLE], title: 'My profile' },
            component: () => import(/* webpackChunkName: "user" */ './components/UserProfile.vue'),
        },

        // Org custom pages:
        {
            path: '/:slug',
            name: 'orgHome',
            meta: { title: 'Home: ' },
            props: true,
            component: () => import(/* webpackChunkName: "main" */ './views/Home.vue'),
        },


        {
            path: '*',
            meta: {  title: 'Not found' },
            component: () => import(/* webpackChunkName: "main" */ './views/PageNotFound.vue'),
        },
      
    ],  // end of routes

    //scrollBehavior: (to, from, savedPosition) => {
    //    let scrollTo = 0;

    //    if (to.hash) {
    //        scrollTo = parseInt(to.hash, 10);
    //    } else if (savedPosition) {
    //        scrollTo = savedPosition.y;
    //    }

    //    return goTo(scrollTo);
    //},


    scrollBehavior(to, from, savedPosition) {        
        let scrollTo = 0;
        if (to.hash) {
            scrollTo = parseInt(to.hash, 10);
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        } 
       
        if (scrollTo !== 0) {
            setTimeout(() => {
                return goTo(scrollTo, { duration: 0 }) as Promise<unknown> as Promise<PositionResult>;
                //    window.scrollTo(0, 0);
            }, 200);
            // Need the delay here to let the new page show 200ms 
            // which is a little more than router-view transition duration in App.vue.
        } else {
            return goTo(0, { duration: 0 }) as Promise<unknown> as Promise<PositionResult>;
        }
    },
                  

});



const DEFAULT_TITLE = 'EdTech Commons';


// Auth check 
router.beforeEach((to: any, from: any, next: any) => {

    // AUTH - 
    const currentRole = store.getters['auth/authRole']; // authService.getCurrentUser();
    const { authorize } = to.meta;
   
    // Role authorization: from a Jason Watmore example
    // uses the "authorize" meta-value, with a list of allowed roles
    if (authorize) {
        if (!store.getters['auth/isAuthenticated']) {
            // not logged in and trying to access page - 
            // redirect to login page with the return url
            next({
                path: '/users/login',
                query: { redirect: to.fullPath },
            });
        }
        // check for "any role" allowed: 
        if (authorize.length && authorize.includes(UserRole.ANYROLE)
            && currentRole !== UserRole.NONE) {
            // skip out of specific role check
            next();
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentRole)) {
            // role not authorised so redirect to home page
            // authorize meta value in route includes all allowed roles
            return next();

            // maybe route to not authorized page or popup alert?
            return next({ path: '/users/login' });
        }
    }

    next(); // always call next()!
});



router.afterEach((to, from) => {

    // Set page title in history
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    let title = '';
    if (to.meta !== undefined) {  // to !== null &&
        title = to.meta.title;
    }

    Vue.nextTick(() => {
        document.title = title + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
    });
    
});



export default router;

