import { format, parseISO } from 'date-fns';

// export default (date: Date) => {
export default (date: string) => {
    try {
        return format(new Date(date), 'M-dd-yyyy ha');
    } catch (e) {
        return ''; //e;
    }
};
