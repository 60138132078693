/*  Authentication Vuex store
 * 
 *  
 */

import { Credentials } from '../../models/credentials.interface';
import { UserRole } from '@/helpers/userRoles';
import { UserAccount } from '@/models/UserAccount';

import { authService } from '../../services/auth.service';
import { EventBus } from '../../event-bus';

import CryptoJS from 'crypto-js';



const AuthStatus = {
    NONE: '',
    LOGGED_IN: 'logged-in',
    LOGGED_OUT: 'logged-out',
    ERROR: 'error',
    TRYING_LOGIN: 'attempting authentication request',
};

//
const state = {
    currentUser: getUserObj(),
    status: localStorage.getItem('auth-status') || AuthStatus.LOGGED_OUT,
    role: localStorage.getItem('role') || UserRole.NONE,    
};




function getUserObj() {
    try {
        const usrString: string = localStorage.getItem('current-user') || '';


        // new: 2-22-22 Paul - maybe intercept empty local user profile, get from server
        // testing 
        //if (usrString === '') {
            // get profile and auth state:
            //actions.authLoadFromServer();

        //}


        return JSON.parse(
            CryptoJS.AES.decrypt(usrString, localStorage.getItem('role') || 'fallback')
            .toString(CryptoJS.enc.Utf8)
            || '{}') as UserAccount;
        
    } catch {
        return {} as UserAccount;
        // Vue doesn't like a null val in the Vuex getters - at least the way I'm using them.
    }

    // handle nulls:
    // "fieldB" : detailsObj.b === undefined ? null : detailsObj.b,
}


const getters = {
    isAuthenticated: (authState: any) => authState.status === AuthStatus.LOGGED_IN,  // !!authState.currentUser, //!!authState.token,
    authUser: (authState: any) => authState.currentUser, // problem w/ type?
    authStatus: (authState: any) => authState.status,
    authRole: (authState: any) => authState.role,

    userOrgName: (authState: any) => authState.currentUser.orgName,
    userOrgNameShort: (authState: any) => authState.currentUser.orgNameShort,
    userOrgId: (authState: any) => authState.currentUser.orgId,
    selectedOrgName: (authState: any) => authState.currentUser.orgName,
    selectedOrgId: (authState: any) => authState.currentUser.orgId,
   
};


const actions = {
    authRequest: ({ commit, dispatch }: { commit: any, dispatch: any }, credentials: Credentials) => {
        return new Promise((resolve, reject) => {
            commit('authRequest');
            authService.login(credentials)
                .subscribe((result: any) => {

                    const id = result.role || 'fallback'; // identityId; //.substring(0,8);
                    //console.log('key: ' + id);
                    const userObjString = CryptoJS.AES.encrypt(JSON.stringify(result), id).toString();

                    localStorage.setItem('current-user', userObjString); //JSON.stringify(result)); // result);  //
                    localStorage.setItem('auth-status', AuthStatus.LOGGED_IN);
                    localStorage.setItem('role', result.role); // UserRole.APPSUPER);  // testing

                    commit('authSuccess', result);
                    EventBus.$emit(AuthStatus.LOGGED_IN, null);

                    // Gets user profile? redundant now.
                    //dispatch('user/userRequest', null, { root: true });

                    resolve(result);
                },
                    (errors: any) => {
                        commit('authError', errors);
                        clearLoginPersistedStorage();               
                        reject(errors);
                    });
        });
    },


    authRefreshLoginState: ({ commit, dispatch }: { commit: any, dispatch: any }) => {
    //ltiAuthRequest: ({ commit, dispatch }: { commit: any, dispatch: any }, credentials: Credentials) => {
        return new Promise((resolve, reject) => {
           // commit('authRefreshLoginState');
            commit('authRequest');
            // get user profile, assuming the LTI http post to server was a successful LTI launch                
            authService.refreshLoginState() 
                .subscribe((result: any) => {

                    localStorage.setItem('current-user', result);
                    localStorage.setItem('auth-status', AuthStatus.LOGGED_IN);
                    localStorage.setItem('role', result.role); 

                    commit('authSuccess', result);
                    EventBus.$emit(AuthStatus.LOGGED_IN, null);

                    console.log('authRefreshLoginState - success');

                    // Gets user profile? 
                    //dispatch('user/userRequest', null, { root: true });

                    resolve(result);
                },
                    (errors: any) => {
                        commit('authError', errors);
                        clearLoginPersistedStorage();
                        reject(errors);
                    });
        });
    },


    authStatusCheck: ({ commit, dispatch }: { commit: any, dispatch: any }) => {
        return new Promise<void>((resolve, reject) => {            
            authService.checkLoginStatus()
                .subscribe((result: any) => {
                //.subscribe((result: any) => {
                    // API returns OK if logged in

                    console.log('checkLoginStatus - success');
                    // 


                    //resolve();   // Paul: 2-22-22 call resolve here?
                    resolve(result);
                },
                    (errors: any) => {

                        // if (error.response.status === 401)

                        console.log('checkLoginStatus - error' + errors);
                        // Which commit?
                        // commit('authError', errors);
                        commit('authLogout');
                        clearLoginPersistedStorage();
                        reject(errors);
                    });
        });
    },






    //facebookAuthRequest: ({ commit, dispatch }: { commit: any, dispatch: any }, accessToken: string) => {
    //    return new Promise((resolve, reject) => {
    //        commit('authRequest');
    //        authService.facebookLogin(accessToken)
    //            .subscribe((result: any) => {
    //                localStorage.setItem('auth-token', result); // stash the auth token in localStorage
    //                commit('authSuccess', result);
    //                EventBus.$emit('logged-in', null);
    //                dispatch('user/userRequest', null, { root: true });
    //                resolve(result);
    //            },
    //                (errors: any) => {
    //                    commit('authError', errors);
    //                    localStorage.removeItem('auth-token');
    //                    reject(errors);
    //                });
    //    });
    //},



    // app logout method - doesn't call server, now at least mid-2021. 
    // JWTs expires on their own - no server state.
    authLogout: ({ commit, dispatch }: { commit: any, dispatch: any }) => {
        return new Promise<void>((resolve, reject) => {
            authService.logout()
                .subscribe(() => {
                    //.subscribe((result: any) => {
                    // API returns OK if logged in

                    // do nothing?
                   // resolve();
                },
                    (errors: any) => {

                        // if (error.response.status === 401)                       
                        reject(errors);
                    });
            commit('authLogout');
            clearLoginPersistedStorage();         
            resolve();
        });
    },
};


// TODO: mutations need to be single values?
const mutations = {
    authRequest: (authState: any) => {
        authState.status = 'attempting authentication request';
    },
    authSuccess: (authState: any, user: UserAccount) => {  // authToken: string) => {
        authState.status = AuthStatus.LOGGED_IN;
        authState.currentUser = user;
        authState.role = user.role;
        //authState.token = authToken;
    },
    authError: (authState: any) => {
        authState.status = AuthStatus.ERROR;
    },
    authLogout: (authState: any) => {
        //authState.token = '';
        authState.status = AuthStatus.LOGGED_OUT;
        authState.currentUser = '';
        authState.role = UserRole.NONE;
    },    
};




function clearLoginPersistedStorage() {
    localStorage.setItem('current-user', '');
    localStorage.setItem('auth-status', AuthStatus.LOGGED_OUT);
    localStorage.setItem('role', UserRole.NONE);

}

//function parseJwt(token) {
//    const base64Url = token.split('.')[1];
//    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//    const buff = new Buffer(base64, 'base64');
//    const payloadinit = buff.toString('ascii');
//    const payload = JSON.parse(payloadinit);
//    console.log(payload);
//    return payload;
//}


export default {
    state,
    getters,
    actions,
    mutations,
};
